
const Admin = 'ROLE_ADMIN';
const User = 'ROLE_USER';
const Moderator = 'ROLE_MODERATOR';
const Operator = 'ROLE_OPERATOR';
const Fake = 'ROLE_FAKE';
const Test = 'ROLE_TEST';

const isAdmin = function (user) {
    return user.roles.filter(role => Admin == role).length > 0;
}
export const Roles = {
    Admin,
    User,
    Moderator,
    Operator,
    Fake,
    Test,
    isAdmin
}
